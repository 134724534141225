import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layout'

const ThanksPage = () => (
  <Layout>
    <div className="thankyou-page">
      <h1><span role="img" aria-label="thanks hands">🙏</span> Thank you for your message.</h1>
      <p>We'll be in touch!</p>

      <Link to="/" className="btn btn-green">Go back home</Link>
    </div>
  </Layout>
)

export default ThanksPage
